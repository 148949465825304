<template>
    <div class="landing-view">
        <BigHeader>
            <template v-slot:title>
                Займ на карту<br/>
                на любые цели
            </template>
        </BigHeader>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

import BigHeader from '@/components/common/big-header/BigHeader'

import Store from '@/store'

import comebacker from '@/mixins/comebacker'

export default {
    name: 'App',
    mixins: [
        comebacker
    ],
    components: {
        BigHeader
    },
    beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-cpa')) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPrimary' })
        }
    }
};
</script>

<style lang="scss">
.landing-view {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>